import { ReactElement } from "react";
import style from "./style.module.scss";

export default function Intro(): ReactElement {
    return (
        <div className={style.wrapper}>
            <div className="mx-auto lg:max-w-7xl flex justify-center text-left md:items-center md:text-center flex-col">
                <h2 className="text-left">What Are 3M Combat Arms Earplugs?</h2>
                <p>
                    3M Combat Arms Earplugs are uniquely designed double-ended
                    earplugs that are meant to offer versatility based on noise
                    conditions. Users can select one side of the earplug over
                    the other, tailoring their hearing protection to the
                    specific environment. One side provides consistent
                    attenuation suitable for indoor range shooting. In contrast,
                    the other side is optimized for noise reduction in scenarios
                    such as hunting, tactical operations, outdoor range
                    shooting, and situations demanding heightened situational
                    awareness.
                </p>
            </div>
        </div>
    );
}
